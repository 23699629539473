import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import dashboard from "../assets/mobile-app2.png"
import mobile from "../assets/mobileapp.png"
import geometry from "../assets/Component 23.png"

export default function Projects() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | Projects</title>
        <link rel="canonical" href="https://aikate.eu/projects" />
        <meta
          name="Our projects"
          content="Software development, IoT solutions, Our projects"
        />
        <meta
          name="description"
          content="Our team has successfully delivered custom software development, Hardware development, mobile app development, and more for clients across various industries. We take pride in our innovative approach and commitment to quality, and we're ready to help you bring your vision to life. "
        ></meta>
      </Helmet>
      <div className="px-4 py-16  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="pt-20 pr-8 grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <h1 className="mb-4 text-5xl sm:text-4xl lg:text-7xl font-extrabold leading-none">
              Our projects
            </h1>
            <p className="mb-6 text-3xl pt-4 text-white">
              We develop applications specifically designed for a particular
              purpose, department, or company. The software can incorporate
              features from other software programs or be written and designed
              to meet the client's specific business processes.
            </p>
            <div className="flex items-center space-x-4"></div>
          </div>
          <div>
            <img className="md:pl-10 sm:h-96" src={geometry} alt="services" />
          </div>
        </div>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <h3 className="inline-block px-3 py-px mb-4 text-md font-semibold tracking-wider text-[#005BEA] uppercase">
              Iot/Product development
            </h3>
          </div>
          <h2 className="max-w-lg mb-6 font-Manrope text-4xl font-bold leading-none tracking-tight sm:text-center text-white lg:text-7xl sm:text-4xl sm:pl-6 md:mx-auto">
            VendLOG
          </h2>
          <p className="text-xl font-Manrope md:m-8 text-white md:text-xl">
            <a
              href="https://www.vendlog.com/?lang=hr"
              className="text-[#005BEA] pr-2 text-xl  font-semibold"
            >
              VendLOG
            </a>
            is a unique telemetry (IoT) device that fiscalizes millions of
            invoices and helps vendors remotely manage and monitor their vending
            machines.
          </p>
        </div>
        <div className="mx-auto lg:max-w-sm md:pl-8">
          <div className="relative w-full items-center rounded transition-shadow duration-300 hover:shadow-xl"></div>
        </div>
      </div>
      <div className="px-4 py-16 bg-[#FBFBFB] rounded-xl mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <p className="text-xl font-Manrope p-4 text-[#181C2B]  md:text-xl">
                Our expertise in embedded systems ensures that our IoT vending
                solution is not only innovative, but also highly functional and
                durable. <br></br>In addition to our IoT vending hardware
                solution and expertise in embedded systems, we also developed a
                web application to complement our product.
              </p>
              <p className="text-xl font-Manrope p-4 text-[#181C2B]  md:text-xl">
                Our web/mobile application allows businesses to remotely manage
                and monitor their vending machines from anywhere, at any time.
                With an intuitive and user-friendly interface, our web
                application provides real-time data on sales, inventory, and
                machine status, making it easy for businesses to optimize their
                vending operations.
              </p>
            </div>
            <p className="mb-4 text-xl text-[#005BEA] p-4 font-bold tracking-widest uppercase">
              Features
            </p>
            <div className="grid space-y-3 p-4 text-[#181C2B] text-xl sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1"></span>
                  real-time monitoring
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                  machine alerts
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                  optimization
                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1"></span>
                  detailed reports
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                  cash collection
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                  contactless payments
                </li>
              </ul>
            </div>
          </div>
          <div className="py-16 mx-auto md:max-w-full">
            <img
              className="object-cover h-96 w-96 md:pl-20 opacity-85 sm:h-full"
              src={dashboard}
              alt="dashboard"
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-16  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto sm:text-center lg:max-w-2xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <h3 className="inline-block px-3 py-px mb-4 text-md font-semibold tracking-wider text-[#005BEA] uppercase">
                Software development/Web application
              </h3>
            </div>
            <h2 className="max-w-lg font-Manrope mb-6 font-sans text-5xl font-bold leading-none tracking-tight text-white lg:text-7xl sm:text-4xl md:mx-auto">
              Easy construction management
            </h2>
            <p className="text-xl text-gray-200">
              Application for project management and coordination of works on
              the construction site.
            </p>
          </div>
          <div className="mb-4 transition-shadow duration-300 hover:shadow-xl lg:mb-6"></div>
        </div>
      </div>
      <div className="px-4 py-16 bg-[#FBFBFB] rounded-xl  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <p className="text-xl text-[#181C2B]">
                We created an app to assist with building process management. It
                makes it simpler to manage construction sites and assign duties
                by providing access to all the projects and documentation. Our
                objective was to make it simple for users to manage projects and
                monitor them.
              </p>
            </div>
            <p className="mb-4 text-xl pt-4 text-[#005BEA] font-bold tracking-widest uppercase">
              Features
            </p>
            <div className="grid space-y-3 gap-3 text-[#181C2B] text-xl sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-3 text-xl">
                <li className="flex">
                  <span className="mr-1"></span>
                  all documentation at the touch of a button
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                  manage construction processes digitally
                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex md:pl-4">
                  <span className="mr-1"></span>
                  easy management of the construction site
                </li>
                <li className="flex md:pl-4">
                  <span className="mr-1"></span>
                  assign tasks and communicate with your project team,
                  investors, or subcontractors
                </li>
                <li className="flex">
                  <span className="mr-1"></span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img
              className="object-cover  md:pl-20 opacity-85 sm:h-96"
              src={mobile}
              alt="mobile phone"
            />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <div className="mb-6 sm:mx-auto"></div>
            <div className="max-w-2xl mb-10 md:mx-auto sm:max-w-md lg:max-w-xl md:mb-12">
              <h2 className="max-w-2xl mb-6 m-8  font-Manrope text-6xl font-bold text-white sm:text-4xl lg:text-5xl md:mx-auto">
                Ready to take your business to the next level?
              </h2>
              <p className="text-base text-white pl-6 sm:text-center sm:pl-6 md:text-lg">
                Contact us today to learn more about how we can help.
              </p>
            </div>
            <div className="sm:pl-6 pl-6">
              <a
                href="mailto:info@aikate.hr"
                className="contactbutton2 inline-flex  items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
